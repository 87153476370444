
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
    timeout: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      secondsPassed: 0,
      secondsTimer: null,
      complete: false,
      reloadedUser: false,
    };
  },
  computed: {
    currentStateText() {
      if (this.secondsPassed < 4) {
        return this.$t('sending_activation_mail.step1', this.$auth.user);
      }
      if (this.secondsPassed < 7) {
        return this.$t('sending_activation_mail.step2', this.$auth.user);
      }
      if (this.secondsPassed < 10) {
        return this.$t('sending_activation_mail.step3', this.$auth.user);
      }
      return this.$t('sending_activation_mail.step4', this.$auth.user);
    },
  },
  beforeDestroy() {
    if (this.secondsTimer) {
      clearInterval(this.secondsTimer);
    }
  },
  mounted() {
    this.secondsTimer = setInterval(() => {
      this.secondsPassed++;
      if (this.secondsPassed >= this.timeout) {
        this.completed();
      }

      this.$axios
        .get('/api/activate.php')
        .then((res) => {
          if (!this.reloadedUser) {
            this.reloadedUser = true;
            this.$auth.fetchUser();
          }
          if (res.data.delivered) {
            this.completed();
          }
        })
        .catch(() => {});
    }, 900);
  },
  methods: {
    completed() {
      if (!this.complete) {
        this.complete = true;
        clearInterval(this.secondsTimer);
        this.$emit('complete');
      }
    },
  },
};
