
import Mailcheck from 'mailcheck';

export default {
  props: {
    containerClass: {
      type: String,
      default: 'mail-check-container',
    },
    suggestionClass: {
      type: String,
      default: 'mail-check-suggestion',
    },
    suggestionDomainClass: {
      type: String,
      default: 'mail-check-domain',
    },
    modelName: {
      type: String,
      required: true,
    },
    data: {
      type: String,
      required: true,
    },
    domains: {
      type: Array,
      required: false,
      default: () => {
        return [
          'gmail.com',
          'hotmail.com',
          'live.nl',
          'outlook.com',
          'ziggo.nl',
          'hotmail.nl',
          'home.nl',
          'kpnmail.nl',
          'upcmail.nl',
          'yahoo.com',
          'icloud.com',
          'hetnet.nl',
          'msn.com',
          'planet.nl',
          'chello.nl',
          'casema.nl',
          'quicknet.nl',
          'telfort.nl',
          'telenet.be',
          'kpnplanet.nl',
          'zeelandnet.nl',
          'online.nl',
          'zonnet.nl',
          'xs4all.nl',
          'versatel.nl',
          'tele2.nl',
          'live.com',
          'me.com',
          'skynet.be',
          'caiway.nl',
          'wp.pl',
          't-mobilethuis.nl',
          'kabelfoon.nl',
          'telfortglasvezel.nl',
          'mail.com',
          'solcon.nl',
          'hccnet.nl',
          'wxs.nl',
          'o2.pl',
          'hotmail.be',
          'onsbrabantnet.nl',
          'yahoo.co.uk',
          'yahoo.nl',
          'live.be',
          'tiscali.nl',
          'interia.pl',
          'concepts.nl',
          'knid.nl',
          'yahoo.de',
          'live.co.uk',
          'outlook.be',
          'googlemail.com',
          'bigpond.com',
          'btinternet.com',
          'live.com.au',
          'ymail.com',
          'gmx.com',
          'sky.com',
          'comcast.net',
          'protonmail.com',
          'proximus.be',
          'aol.co.uk',
          'rocketmail.com',
          'optusnet.com.au',
        ];
      },
    },
    topLevelDomains: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    secondLevelDomains: {
      type: Array,
      required: false,
      default: () => {
        return ['hotmail'];
      },
    },
    distanceFunction: {
      type: Function,
      required: false,
      default: Mailcheck.sift4Distance,
    },
  },
  data() {
    return {
      suggestion: null,
    };
  },
  computed: {
    displaySuggestion() {
      return this.suggestion && this.suggestion.full !== this.data;
    },
  },
  watch: {
    data: 'verifyEmail',
  },
  methods: {
    verifyEmail() {
      const vm = this;
      Mailcheck.run({
        email: vm.data,
        domains: vm.domains, // optional
        topLevelDomains: vm.topLevelDomains, // optional
        secondLevelDomains: vm.secondLevelDomains, // optional
        distanceFunction: vm.distanceFunction, // optional
        topLevelThreshold: 1,
        domainThreshold: 2,
        suggested: (suggestion) => {
          vm.suggestion = suggestion;
        },
        empty: () => {
          vm.suggestion = null;
        },
      });
    },
    UpdateParent() {
      this.$parent[this.modelName] = this.suggestion.full;
      this.suggestion = null;
    },
  },
};
