
import Dropzone from 'nuxt-dropzone';
import 'nuxt-dropzone/dropzone.css';
const minAge = (param) =>
  function (value) {
    if (!value) {
      return false;
    }
    const x = (value + '').split('-');
    if (x[0] === '0000' || x[1] === '00' || x[2] === '00') {
      return false;
    }
    const d = new Date(parseInt(x[0]), parseInt(x[1]) - 1, parseInt(x[2]));

    const d2 = new Date();
    d2.setFullYear(d2.getFullYear() - param);

    return d2 >= d;
  };

export default {
  components: { Dropzone },

  props: {
    profile: {
      type: Object,
      required: false,
      default: null,
    },
    displaySkipDoiButton: {
      type: Boolean,
      default: true,
    },
    autoSkipSteps: {
      type: Boolean,
      default: true,
    },
    showtitle: {
      type: Boolean,
      default: true,
    },
    showdots: {
      type: Boolean,
      default: true,
    },
    showtextmuted: {
      type: Boolean,
      default: true,
    },
    lightlabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailToken: null,
      minAge: 18,
      profile_fields: null,
      hasCoordinates: false,
      dropzone_options: {
        url: '/api/profileimage.php',
        withCredentials: true,
        acceptedFiles: 'image/png,image/jpg,image/jpeg,image/gif',
        maxFiles: 2,
      },
      timezone: null,
      step: 0,
      submitting: false,
      existingEmailStatus: null,
      userCountry: null,
      userCountryregion: null,
      userCity: null,
      userProfile: {},
      numPages: 6,
    };
  },
  async fetch() {
    const [profileFields] = await Promise.all([
      this.$axios.get('/api/profile_fields.php'),
      this.$store.dispatch('settings/load_region_data'),
    ]);

    this.profile_fields = profileFields.data;
  },
  computed: {
    defaultProfileFields() {
      const custom = ['age', 'gender', 'city', 'countryregion', 'country'];
      return this.editableProfileFields.filter((x) => !custom.includes(x));
    },
    editableProfileFields() {
      return this.$store.state.settings.visible_profile_fields;
    },
    editableProfileNotes() {
      return this.$store.state.settings.visible_profile_notes;
    },
    countryOptions() {
      return this.$store.state.settings.countries.map((x) => {
        return { value: x, text: this.countryName(x) };
      });
    },
    countryRegionOptions() {
      if (!this.userCountry) {
        return [
          {
            value: null,
            text: this.$t('choose_country'),
            disabled: true,
          },
        ];
      } else if (
        this.$store.state.settings.region_data &&
        this.$store.state.settings.region_data[this.userCountry]
      ) {
        const r = this.$store.state.settings.region_data[this.userCountry].map(
          (x) => {
            return {
              value: x.id,
              text: x.name,
            };
          }
        );
        r.unshift({
          value: null,
          text: this.$t('choose_region', {
            region_txt: this.$t('region.' + this.userCountry),
          }),
          disabled: true,
        });
        return r;
      } else {
        return [];
      }
    },
  },
  watch: {
    displayPushOption(newValue) {
      if (this.step === 1 && newValue === false) {
        this.step++;
      }
    },
    step(to) {
      this.$emit('progressChanged', { progress: to / this.numPages });
      if (to === 3) {
        this.getUserCoordinates();
      }
      if (to === 7) {
        this.$emit('signupFlowComplete');
        if (process.client && this.$gtag) {
          this.$gtag('event', 'signup-complete');
        }
      } else if (process.client && this.$gtag) {
        this.$gtag('event', 'signup-step-' + to);
      }
    },
  },
  mounted() {
    if (this.$auth.loggedIn) {
      this.loadUserToData();
      // check which can might be skipped
      this.step = 1;
      if (this.displayPushOption === false) {
        this.step = 2;
      }
      if (this.$auth.user.profile.main_image) {
        this.step = 3;
      }
      if (this.autoSkipSteps) {
        if (
          this.$auth.user.profile.countryregion ||
          this.$auth.user.profile.city
        ) {
          this.step = 4;
        }
        if (
          this.$auth.user.profile.gender ||
          this.$auth.user.profile.date_of_birth
        ) {
          this.step = 5;
        }
        if (this.$auth.user.profile.personal) {
          this.step = 6;
        }
      }
    }

    try {
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {}
  },
  validations() {
    if (this.step === 0) {
      return {
        formEmail: {
          emailServerValidation(value) {
            this.existingEmailStatus = null;
            if (value === '') {
              return false;
            }
            return this.$axios
              .get('api/validate_email.php', {
                progress: false,
                params: {
                  email: value,
                },
              })
              .then((res) => {
                this.emailToken = res.data.token;
                if (res.data.status === 'new') {
                  if (
                    res.data.request_email !== res.data.email &&
                    res.data.request_email === this.formEmail
                  ) {
                    this.formEmail = res.data.email;
                  }
                  return true;
                }
                this.existingEmailStatus = res.data.status;
                return false;
              })
              .catch((error) => {
                if (
                  error.message.includes('404') ||
                  error.message.includes('403')
                ) {
                  this.existingEmailStatus = 'invalid';
                }
                return false;
              });
          },
        },
        formPassword: {
          nonEmpty(a) {
            return a.length > 0;
          },
        },
        formName: {
          nonEmpty(a) {
            return a.length > 0;
          },
          notStartingWithAt(a) {
            return !a.startsWith('@');
          },
          notEqualToEmail(a) {
            return a !== this.formEmail;
          },
        },
      };
    }
    if (this.step === 4) {
      return {
        userProfile: {
          date_of_birth: {
            validDate(a) {
              if (!a) {
                return false;
              }
              const x = (a + '').split('-');
              if (x[0] === '0000' || x[1] === '00' || x[2] === '00') {
                return false;
              }
              const d = new Date(
                parseInt(x[0]),
                parseInt(x[1]) - 1,
                parseInt(x[2])
              );
              if (
                d.getMonth() + 1 === parseInt(x[1]) &&
                d.getFullYear() === parseInt(x[0]) &&
                d.getDate() === parseInt(x[2])
              ) {
                return true;
              }
              return false;
            },
            minAge: minAge(this.minAge),
          },
        },
      };
    }

    return {};
  },
  methods: {
    async updateProfile() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$pending) {
        await this.$forceNextTick();
      }
      await this.isValid();
      if (this.$v.$invalid) {
        return false;
      } else {
        const params = this.userProfile;
        params.lastSignupStep = this.step === 5;
        this.step++;

        this.$axios
          .put('/api/user.php', params)
          .then((res) => {
            this.$auth.fetchUser();
          })
          .catch(() => {});
      }
    },

    optionsFor(field) {
      if (
        this.profile_fields &&
        this.profile_fields.allowed_values &&
        this.profile_fields.allowed_values[field]
      ) {
        const r = this.profile_fields.allowed_values[field].map((x) => {
          return {
            value: x,
            text: this.$te('profile_settings.' + field + '.' + x)
              ? this.$t('profile_settings.' + field + '.' + x)
              : this.$t(x),
          };
        });
        r.unshift({
          value: null,
          text: this.$t('choose'),
          disabled: true,
        });
        return r;
      }
      return [];
    },
    getUserCoordinates() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.reverseGeoCode);
      }
    },
    reverseGeoCode(pos) {
      if (this.hasCoordinates) {
        return;
      }
      this.hasCoordinates = true;
      this.$axios
        .get('/api/geocode.php', {
          params: {
            lat: pos.coords.latitude,
            lon: pos.coords.longitude,
          },
        })
        .then((res) => {
          if (this.$store.state.settings.countries.includes(res.data.country)) {
            this.userCountry = res.data.country;
            if (res.data.countryregion) {
              this.userCountryregion = res.data.countryregion;
            }
          }
          if (!this.userCity) {
            this.userCity = res.data.city;
          }
        });
    },
    loadUserToData() {
      this.userCountry = this.$auth.user.profile.country;
      this.userCountryregion = this.$auth.user.profile.countryregion;
      this.userCity = this.$auth.user.profile.city;
      this.userProfile = { ...this.$auth.user.profile };
    },
    updateProfileLocation() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      this.step++;
      this.$axios
        .put('/api/user.php', {
          country: this.userCountry,
          countryregion: this.userCountryregion,
          city: this.userCity,
        })
        .then((res) => {
          this.submitting = false;
          this.$auth.fetchUser();

          this.userProfile.country = this.userCountry;
          this.userProfile.countryregion = this.userCountryregion;
          this.userProfile.city = this.userCity;
        })
        .catch(() => {
          // handle error
          this.submitting = false;
        });
    },

    _validations() {
      return new Promise((resolve) => {
        if (this.$v.$error || !this.$v.$pending) {
          return resolve();
        }
        const poll = setInterval(() => {
          if (!this.$v.$pending) {
            clearInterval(poll);
            resolve();
          }
        }, 200);
      });
    },
    async isValid() {
      this.$v.$reset();
      this.$v.$touch();
      await this._validations();
      return Promise.resolve(!this.$v.$error);
    },

    emailChanged() {
      // generate profile name
      if (this.formName === '') {
        const index = this.formEmail.indexOf('@');
        if (index > 0) {
          this.formName = this.formEmail.substr(0, index);
        }
      }
    },
    async createAccount() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$pending) {
        await this.$forceNextTick();
      }
      await this.isValid();
      if (this.$v.$invalid) {
        return false;
      } else {
        if (this.submitting) {
          return false;
        }
        this.submitting = true;
        const emailAddress = this.formEmail;
        let r = null;
        try {
          r = await crypto.subtle
            .digest(
              'SHA-512',
              new TextEncoder('utf-8').encode(
                emailAddress.toLowerCase() + navigator.userAgent
              )
            )
            .then((buf) => {
              return Array.prototype.map
                .call(new Uint8Array(buf), (x) =>
                  ('00' + x.toString(16)).slice(-2)
                )
                .join('');
            });
        } catch (e) {}
        this.$axios
          .post('/api/user.php?new=1', {
            email: emailAddress,
            name: this.formName,
            password: this.formPassword,
            trafficsource: this.$auth.$storage.getUniversal('network'),
            timezone: this.timezone || 'UTC',
            country: this.$store.state.settings.geoip_selected_country,
            language: this.$i18n.locale,
            profile: this.profile ? this.profile.id : null,
            form_path: this.$route.path,
            referrer:
              document.referrer && !document.referrer.startsWith(window.origin)
                ? document.referrer
                : null,
            csrf: r,
            token: this.emailToken,
            n: navigator.userAgent,
          })
          .then((res) => {
            this.$emit('signupStarted');
            this.$auth
              .loginWith('cookie', {
                data: {},
              })
              .then(() => {
                this.submitting = false;
                this.userCountry =
                  this.$store.state.settings.geoip_selected_country;
                this.$emit('accountCreated');
                this.loadUserToData();

                if (this.pushSupported) {
                  // if already subscribed; just save and continue
                  if (this.hasPushSubscription) {
                    this.addPushSubscriptionToAccount();
                    this.step += 2;
                  } else if (this.pushDenied) {
                    this.step += 2;
                  } else {
                    this.step++;
                  }
                } else {
                  this.step += 2;
                }
              });
          })
          .catch((error) => {
            // handle error
            this.submitting = false;
            alert(error + ': ' + error.response.data.error);
          });
      }
    },
  },
};
