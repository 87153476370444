
export default {
  name: 'MailProviderLinks',
  computed: {
    gmailSearchURL() {
      const search =
        'from:"' + this.$store.state.settings.name + '" in:anywhere';

      let gmailUrl = 'https://mail.google.com/mail/u/0/#search/';
      if (this.$ua.isFromSmartphone()) {
        gmailUrl = 'https://mail.google.com/mail/mu/mp/635/#tl/search/';
        if (this.$ua.isFromAndroidMobile()) {
          gmailUrl = 'https://mail.google.com/mail/mu/mp/486/#tl/search/';
        }
      }
      return gmailUrl + encodeURIComponent(search).replace('%20', '+');
    },
  },
};
