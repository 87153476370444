
export default {
  name: 'DateOfBirthPicker',
  props: {
    useInputDate: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    fieldOrder: {
      type: String,
      default: 'auto',
    },
    inputClass: {
      type: String,
      default: 'form-control',
    },
    inputContainerClass: {
      type: String,
      default: '',
    },
    selectClass: {
      type: String,
      default: 'custom-select',
    },
    selectContainerClass: {
      type: String,
      default: 'row',
    },
    dayContainerClass: {
      type: String,
      default: 'col-3',
    },
    monthContainerClass: {
      type: String,
      default: 'col-5',
    },
    yearContainerClass: {
      type: String,
      default: 'col-4',
    },
    minAge: {
      type: Number,
      default: 18,
    },
    chooseDayText: {
      type: String,
      default: 'Dag',
    },
    chooseMonthText: {
      type: String,
      default: 'Maand',
    },
    chooseYearText: {
      type: String,
      default: 'Jaar',
    },
    maxAge: {
      type: Number,
      default: 100,
    },
    yearsAscending: {
      type: Boolean,
      default: false,
    },
    useRandomDate: {
      type: Boolean,
      default: false,
    },
    displayLocalizedMonths: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    const end = new Date();
    end.setFullYear(end.getFullYear() - this.minAge);
    const start = new Date();
    start.setFullYear(
      start.getFullYear() -
        (this.minAge < 40 && this.maxAge > 50 ? 50 : this.maxAge)
    );
    const d = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );

    return {
      usedFieldOrder: this.fieldOrder,
      generatedRandomInitalDate: [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2),
      ].join('-'),
    };
  },
  computed: {
    localizedMonths() {
      const r = [];
      for (let i = 0; i < 12; i++) {
        const objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(i);

        r.push(objDate.toLocaleString(this.$i18n.locale, { month: 'long' }));
      }
      return r;
    },
    shouldAddPlaceholder() {
      if (this.value || !this.placeholder) {
        return 0;
      }
      return 1;
    },
    day: {
      set(v) {
        let x = this.valueOrInitialDate.split('');
        x.splice(8, 2, v);
        x = x.join('');
        this.$emit('input', x);
        if (this.month !== '00' && this.year !== '0000') {
          this.$emit('blur', x);
        }
      },
      get() {
        return this.valueOrInitialDate.slice(8, 10);
      },
    },
    month: {
      set(v) {
        let x = this.valueOrInitialDate.split('');
        x.splice(5, 2, v);
        x = x.join('');
        this.$emit('input', x);
        if (this.day !== '00' && this.year !== '0000') {
          this.$emit('blur', x);
        }
      },
      get() {
        return this.valueOrInitialDate.slice(5, 7);
      },
    },
    year: {
      set(v) {
        let x = this.valueOrInitialDate.split('');
        x.splice(0, 4, v);
        x = x.join('');
        this.$emit('input', x);
        if (this.month !== '00' && this.day !== '00') {
          this.$emit('blur', x);
        }
      },
      get() {
        return this.valueOrInitialDate.slice(0, 4);
      },
    },
    cleanValueOrInitialDate() {
      if (this.value) {
        return this.value;
      }
      if (!this.useRandomDate) {
        return '';
      }
      return this.generatedRandomInitalDate;
    },
    valueOrInitialDate() {
      if (this.value) {
        return this.value;
      }
      if (!this.useRandomDate) {
        if (this.showInputDate()) {
          return '';
        }
        return '0000-00-00';
      }
      this.$emit('input', this.generatedRandomInitalDate);
      return this.generatedRandomInitalDate;
    },
    maxDate() {
      const d = new Date();
      d.setFullYear(d.getFullYear() - this.minAge);
      return [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2),
      ].join('-');
    },
    minDate() {
      const d = new Date();
      d.setFullYear(d.getFullYear() - this.maxAge);
      return [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2),
      ].join('-');
    },
    days() {
      const x = [];
      for (let i = 1; i <= 31; i++) {
        let s = i + '';
        if (i < 10) {
          s = '0' + s;
        }
        x.push({
          value: s,
          text: i,
        });
      }
      return x;
    },
    months() {
      const x = [];
      for (let i = 1; i <= 12; i++) {
        let s = i + '';
        if (i < 10) {
          s = '0' + s;
        }
        x.push({
          value: s,
          text: this.displayLocalizedMonths ? this.localizedMonths[i - 1] : i,
        });
      }
      return x;
    },
    years() {
      const d = new Date();

      const minYear = d.getFullYear() - this.maxAge;
      const maxYear = d.getFullYear() - this.minAge;
      let years = [];
      for (let i = minYear; i <= maxYear; i++) {
        years.push(i);
      }

      if (!this.yearsAscending) {
        years = years.reverse();
      }
      return years;
    },
  },
  mounted() {
    if (this.fieldOrder === 'auto') {
      const formats = {
        'af-za': 'YMD',
        'am-et': 'DMY',
        'ar-ae': 'DMY',
        'ar-bh': 'DMY',
        'ar-dz': 'DMY',
        'ar-eg': 'DMY',
        'ar-iq': 'DMY',
        'ar-jo': 'DMY',
        'ar-kw': 'DMY',
        'ar-lb': 'DMY',
        'ar-ly': 'DMY',
        'ar-ma': 'DMY',
        'ar-om': 'DMY',
        'ar-qa': 'DMY',
        'ar-sa': 'DMY',
        'ar-sy': 'DMY',
        'ar-tn': 'DMY',
        'ar-ye': 'DMY',
        'arn-cl': 'DMY',
        'as-in': 'DMY',
        'az-cyrl-az': 'DMY',
        'az-latn-az': 'DMY',
        'ba-ru': 'DMY',
        'be-by': 'DMY',
        'bg-bg': 'DMY',
        'bn-bd': 'DMY',
        'bn-in': 'DMY',
        'bo-cn': 'YMD',
        'br-fr': 'DMY',
        'bs-cyrl-ba': 'DMY',
        'bs-latn-ba': 'DMY',
        'ca-es': 'DMY',
        'co-fr': 'DMY',
        'cs-cz': 'DMY',
        'cy-gb': 'DMY',
        'da-dk': 'DMY',
        'de-at': 'DMY',
        'de-ch': 'DMY',
        'de-de': 'DMY',
        'de-li': 'DMY',
        'de-lu': 'DMY',
        'dsb-de': 'DMY',
        'dv-mv': 'DMY',
        'el-gr': 'DMY',
        'en-029': 'MDY',
        'en-au': 'DMY',
        'en-bz': 'DMY',
        'en-ca': 'DMY',
        'en-gb': 'DMY',
        'en-ie': 'DMY',
        'en-in': 'DMY',
        'en-jm': 'DMY',
        'en-my': 'DMY',
        'en-nz': 'DMY',
        'en-ph': 'MDY',
        'en-sg': 'DMY',
        'en-tt': 'DMY',
        'en-us': 'MDY',
        'en-za': 'YMD',
        'en-zw': 'MDY',
        'es-ar': 'DMY',
        'es-bo': 'DMY',
        'es-cl': 'DMY',
        'es-co': 'DMY',
        'es-cr': 'DMY',
        'es-do': 'DMY',
        'es-ec': 'DMY',
        'es-es': 'DMY',
        'es-gt': 'DMY',
        'es-hn': 'DMY',
        'es-mx': 'DMY',
        'es-ni': 'DMY',
        'es-pa': 'MDY',
        'es-pe': 'DMY',
        'es-pr': 'DMY',
        'es-py': 'DMY',
        'es-sv': 'DMY',
        'es-us': 'MDY',
        'es-uy': 'DMY',
        'es-ve': 'DMY',
        'et-ee': 'DMY',
        'eu-es': 'YMD',
        'fa-ir': 'MDY',
        'fi-fi': 'DMY',
        'fil-ph': 'MDY',
        'fo-fo': 'DMY',
        'fr-be': 'DMY',
        'fr-ca': 'YMD',
        'fr-ch': 'DMY',
        'fr-fr': 'DMY',
        'fr-lu': 'DMY',
        'fr-mc': 'DMY',
        'fy-nl': 'DMY',
        'ga-ie': 'DMY',
        'gd-gb': 'DMY',
        'gl-es': 'DMY',
        'gsw-fr': 'DMY',
        'gu-in': 'DMY',
        'ha-latn-ng': 'DMY',
        'he-il': 'DMY',
        'hi-in': 'DMY',
        'hr-ba': 'DMY',
        'hr-hr': 'DMY',
        'hsb-de': 'DMY',
        'hu-hu': 'YMD',
        'hy-am': 'DMY',
        'id-id': 'DMY',
        'ig-ng': 'DMY',
        'ii-cn': 'YMD',
        'is-is': 'DMY',
        'it-ch': 'DMY',
        'it-it': 'DMY',
        'iu-cans-ca': 'DMY',
        'iu-latn-ca': 'DMY',
        'ja-jp': 'YMD',
        'ka-ge': 'DMY',
        'kk-kz': 'DMY',
        'kl-gl': 'DMY',
        'km-kh': 'YMD',
        'kn-in': 'DMY',
        'ko-kr': 'YMD',
        'kok-in': 'DMY',
        'ky-kg': 'DMY',
        'lb-lu': 'DMY',
        'lo-la': 'DMY',
        'lt-lt': 'YMD',
        'lv-lv': 'YMD',
        'mi-nz': 'DMY',
        'mk-mk': 'DMY',
        'ml-in': 'DMY',
        'mn-mn': 'YMD',
        'mn-mong-cn': 'YMD',
        'moh-ca': 'MDY',
        'mr-in': 'DMY',
        'ms-bn': 'DMY',
        'ms-my': 'DMY',
        'mt-mt': 'DMY',
        'nb-no': 'DMY',
        'ne-np': 'MDY',
        'nl-be': 'DMY',
        'nl-nl': 'DMY',
        'nn-no': 'DMY',
        'nso-za': 'YMD',
        'oc-fr': 'DMY',
        'or-in': 'DMY',
        'pa-in': 'DMY',
        'pl-pl': 'YMD',
        'prs-af': 'DMY',
        'ps-af': 'DMY',
        'pt-br': 'DMY',
        'pt-pt': 'DMY',
        'qut-gt': 'DMY',
        'quz-bo': 'DMY',
        'quz-ec': 'DMY',
        'quz-pe': 'DMY',
        'rm-ch': 'DMY',
        'ro-ro': 'DMY',
        'ru-ru': 'DMY',
        'rw-rw': 'MDY',
        'sa-in': 'DMY',
        'sah-ru': 'MDY',
        'se-fi': 'DMY',
        'se-no': 'DMY',
        'se-se': 'YMD',
        'si-lk': 'YMD',
        'sk-sk': 'DMY',
        'sl-si': 'DMY',
        'sma-no': 'DMY',
        'sma-se': 'YMD',
        'smj-no': 'DMY',
        'smj-se': 'YMD',
        'smn-fi': 'DMY',
        'sms-fi': 'DMY',
        'sq-al': 'YMD',
        'sr-cyrl-ba': 'DMY',
        'sr-cyrl-cs': 'DMY',
        'sr-cyrl-me': 'DMY',
        'sr-cyrl-rs': 'DMY',
        'sr-latn-ba': 'DMY',
        'sr-latn-cs': 'DMY',
        'sr-latn-me': 'DMY',
        'sr-latn-rs': 'DMY',
        'sv-fi': 'DMY',
        'sv-se': 'YMD',
        'sw-ke': 'MDY',
        'syr-sy': 'DMY',
        'ta-in': 'DMY',
        'te-in': 'DMY',
        'tg-cyrl-tj': 'DMY',
        'th-th': 'DMY',
        'tk-tm': 'DMY',
        'tn-za': 'YMD',
        'tr-tr': 'DMY',
        'tt-ru': 'DMY',
        'tzm-latn-dz': 'DMY',
        'ug-cn': 'YMD',
        'uk-ua': 'DMY',
        'ur-pk': 'DMY',
        'uz-cyrl-uz': 'DMY',
        'uz-latn-uz': 'DMY',
        'vi-vn': 'DMY',
        'wo-sn': 'DMY',
        'xh-za': 'YMD',
        'yo-ng': 'DMY',
        'zh-cn': 'YMD',
        'zh-hk': 'DMY',
        'zh-mo': 'DMY',
        'zh-sg': 'DMY',
        'zh-tw': 'YMD',
        'zu-za': 'YMD',
      };
      this.usedFieldOrder = formats[navigator.language.toLowerCase()] || 'DMY';
    }
  },
  methods: {
    inputDateChanged($event) {
      this.$emit('input', $event.target.value);
    },
    inputDateBlur($event) {
      this.$emit('blur', $event);
    },
    showInputDate() {
      return this.useInputDate && this.isAppleMobile();
    },
    isAppleMobile() {
      return this.$ua.isFromIphone() || this.$ua.isFromIpod();
    },
  },
};
