import { render, staticRenderFns } from "./SignupForm.vue?vue&type=template&id=a51249c2"
import script from "./SignupForm.vue?vue&type=script&lang=js"
export * from "./SignupForm.vue?vue&type=script&lang=js"
import style0 from "./SignupForm.vue?vue&type=style&index=0&id=a51249c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MailCheck: require('/home/code/flirt-frontends/packages/twizie/components/MailCheck.vue').default,DateOfBirthPicker: require('/home/code/flirt-frontends/packages/twizie/components/DateOfBirthPicker.vue').default,SignupSendingActivationMailProgress: require('/home/code/flirt-frontends/packages/twizie/components/SignupSendingActivationMailProgress.vue').default,MailProviderLinks: require('/home/code/flirt-frontends/packages/twizie/components/MailProviderLinks.vue').default})
